import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick'
import { kebabCase, shuffle, uniqueId } from 'lodash'
import { MdArrowForward } from 'react-icons/md'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import windmill from '../images/IMG_0176.jpg'


import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import Overlay from '../components/elements/Overlay'
import swish from '../images/brighton_symbol.svg'
import { above, colors } from '../components/theme'

const HeroWrapper = styled(Hero)`
  ${above.md`
    p {
      max-width: 75%;
    }
 `}
  ${above.lg`
    p {
      max-width: 65%;
    }
 `}
`

const AboutSection = styled(Box)`
  a {
    color: ${props => props.theme.colors.white};
  }
  a p {
    margin-top: 0.5em;
  }
  svg {
    fill: ${props => props.theme.colors.white};
  }
  h2 {
    color: ${props => props.theme.colors.white};
  }
`

const ServicesGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  ${above.md`   
      grid-template-columns: repeat(2, 1fr);
 `}
  ${above.lg`   
      grid-template-columns: repeat(3, 1fr);
 `}
`

const IndexPage = ({ data }) => {
  const page = data.prismicHomepage.data
  const slideshow = page.hero_images
  const randomSlides = shuffle(slideshow)
  const heroSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <HeroWrapper>
        {/* Slideshow */}
        <Slider {...heroSettings}>
          {randomSlides.map(({ image }) => (
            <BackgroundImage
              Tag="div"
              fluid={image.fluid}
              key={`${uniqueId('bg_img_')}`}
            >
              <Container
                css={`
                  position: relative;
                  z-index: 5;
                  display: block;
                `}
              >
                <Box width={1} pt={['40vh', '60vh']} pb="5em">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.hero_text.html,
                    }}
                  />
                </Box>
              </Container>
              <Overlay />
            </BackgroundImage>
          ))}
        </Slider>
      </HeroWrapper>

      <main>
        <Section
          css={`
            background: url(${swish}) no-repeat center 25%;
          `}
        >
          <Container mb={12}>
            <Flex flexWrap="wrap">
              <Box width={[1, 1, 1 / 2]}>
                <h2>Services</h2>
                <h3>{data.prismicServicespage.data.hero_text.text}</h3>
              </Box>
            </Flex>
          </Container>

          <Box>
            <Container>
              <ServicesGrid>
                {data.allPrismicService.edges.map(({ node: service }) => (
                  <Box
                    key={`${uniqueId('service_')}`}
                    className="service"
                    css={`
                      background: ${colors.white};
                      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    `}
                  >
                    <Link
                      to={`services#${kebabCase(service.data.title.text)}`}
                      css={`
                        text-decoration: none;
                        color: ${colors.black};
                      `}
                    >
                      <Img fluid={service.data.service_image.fluid} />
                      <Box
                        css={`
                          padding: 1em;
                        `}
                      >
                        <h4
                          css={`
                            margin-bottom: 0.25em;
                          `}
                        >
                          {service.data.title.text}{' '}
                          <MdArrowForward
                            css={`
                              font-size: 1.25em;
                              position: relative;
                              top: 5px;
                            `}
                          />
                        </h4>
                        <h5>{service.data.subheading.text}</h5>
                      </Box>
                    </Link>
                  </Box>
                ))}
              </ServicesGrid>
            </Container>
          </Box>
        </Section>

        <AboutSection bg={colors.green[900]} color={colors.white}>
          <BackgroundImage
            Tag="div"
            fluid={data.prismicAboutpage.data.home_about_background.fluid}
          >
            <Container py={[13, 14, 15]}>
              <Flex flexWrap="wrap" mx={-8}>
                <Box width={[1, 1, 3 / 4]} px={8}>
                  <h2>About</h2>
                  <h3>
                    {data.prismicAboutpage.data.home_about_background.alt}
                  </h3>
                </Box>
                <Box width={[1, 4 / 5, 1 / 2]} px={8} mb={[8, 0]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.content.html,
                    }}
                  />
                  <Link to="about">
                    <p>
                      More About Us{' '}
                      <MdArrowForward
                        css={`
                          font-size: 1.25em;
                          position: relative;
                          top: 5px;
                        `}
                      />
                    </p>
                  </Link>
                </Box>
                <Box width={[1, 4 / 5, 1 / 2]} px={8} mb={[8, 0]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.content_alt.html,
                    }}
                  />

                  
                  <Link to="faq">
                    <p>
                      Read Our FAQs{' '}
                      <MdArrowForward
                        css={`
                          font-size: 1.25em;
                          position: relative;
                          top: 5px;
                        `}
                      />
                    </p>
                  </Link>

                  <Box mt={8}>
                   
                    
                    <img src={windmill} alt="windmill above the clouds" /></Box>
                </Box>
              </Flex>
            </Container>
          </BackgroundImage>
        </AboutSection>
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query homePage {
    prismicHomepage {
      data {
        content {
          html
        }
        content_alt {
          html
        }
        hero_images {
          image {
            alt
            fluid(maxWidth: 1500) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
        }
        hero_text {
          html
          text
        }
        meta_description
        meta_title
        title {
          text
        }
      }
    }
    prismicServicespage {
      data {
        title {
          text
        }
        hero_text {
          html
          text
        }
      }
    }
    allPrismicService(sort: { fields: data___position, order: ASC }) {
      edges {
        node {
          data {
            position
            title {
              text
            }
            subheading {
              text
            }
            service_image {
              fluid(maxWidth: 1500) {
                ...GatsbyPrismicImageFluid_noBase64
              }
            }
          }
        }
      }
    }
    prismicAboutpage {
      data {
        title {
          text
        }
        content {
          html
        }
        hero_text {
          html
          text
        }
        home_about_background {
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          alt
        }
      }
    }
  }
`
